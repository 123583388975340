<template>
  <div id="facility-list">
    <!-- list filters -->
    <v-card
      class="mb-6"
    >
      <v-card-title>
        Facilities
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            rounded
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="facilityListTable"
        :options.sync="options"
        :server-items-length="totalFacilityListTable"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100]
        }"
        :items-per-page="25"
        show-select
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.icon ? '' : 'primary'"
              :class="item.icon ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.icon"
                :src="require(`@/assets/images/facilities/${item.icon}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'facility-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
              <small>{{item.location}}</small>
            </div>
          </div>
        </template>

        <!-- KMHFL Code -->
        <template #[`item.kmhfl_code`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.kmhfl_code }}</span>
        </template>

        <!-- progress -->
        <template #[`item.progress`]="{item}">
          <span>0.00%</span>
          <v-progress-linear
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveProgressVariant(80)"
            :value="5"
          ></v-progress-linear>
        </template>

        <!-- allocation -->
        <template #[`item.allocation`]="{item}">
          <div class="text-center font-weight-semibold text--primary">0.00</div>
        </template>

        <!-- received -->
        <template #[`item.received`]="{item}">
          <div class="text-center c text--primary">{{ resolveFacilityInventory(item.inventory, 'quantity_received') }}</div>
        </template>

        <!-- issued -->
        <template #[`item.issued`]="{item}">
          <div class="text-center">{{ resolveFacilityInventory(item.inventory, 'quantity_used') }}</div>
        </template>

        <!-- received -->
        <template #[`item.returned`]="{item}">
          <div class="text-center">{{ resolveFacilityInventory(item.inventory, 'quantity_returned') }}</div>
        </template>

        <!-- available -->
        <template #[`item.available`]="{item}">
          <div class="text-center font-weight-semibold text--primary">{{ resolveFacilityInventory(item.inventory, 'quantity_holding') }}</div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <!-- @click.stop="isEditUserSidebarActive = !isEditUserSidebarActive" -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                rounded
                small
                color="info"
                :to="{ name : 'facility-view', params : { id : item.id } }"
              >
                view
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiExportVariant, mdiDotsVertical, mdiFormatListBulletedSquare } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import store from '@/store'
import storeModule from '../StoreModule'
import useFacilityList from './useFacilityList'

export default {
  components: {},
  setup() {
    const FACILITY_STORE_MODULE_NAME = 'facility'
    if (!store.hasModule(FACILITY_STORE_MODULE_NAME)) store.registerModule(FACILITY_STORE_MODULE_NAME, storeModule)

    const {
      facilityListTable,
      tableColumns,
      searchQuery,
      totalFacilityListTable,
      loading,
      options,
      selectedRows,
      fetchFacilities,
      resolveFacilityInventory,
      resolveProgressVariant,
    } = useFacilityList()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FACILITY_STORE_MODULE_NAME)) store.unregisterModule(FACILITY_STORE_MODULE_NAME)
    })

    return {
      facilityListTable,
      tableColumns,
      searchQuery,
      totalFacilityListTable,
      loading,
      options,
      selectedRows,
      icons: {
        mdiExportVariant,
        mdiDotsVertical,
        mdiFormatListBulletedSquare,
      },
      avatarText,
      fetchFacilities,
      resolveFacilityInventory,
      resolveProgressVariant,
    }
  },
  methods: {},
}
</script>
