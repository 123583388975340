import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useFacilityList() {
  const facilityListTable = ref([])

  const tableColumns = [
    { text: 'FACILITY', value: 'name' },
    { text: 'KMHFL', value: 'kmhfl_code', sortable: false },
    { text: 'PROGRESS', value: 'progress', sortable: false },
    { text: 'ALLOCATION', value: 'allocation', sortable: false },
    { text: 'RECEIVED', value: 'received', sortable: false },
    { text: 'ISSUED', value: 'issued', sortable: false },
    { text: 'RETURNED', value: 'returned', sortable: false },
    { text: 'AVAILABLE', value: 'available', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalFacilityListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['names'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  // fetch data
  const fetchFacilities = () => {
    store
      .dispatch('facility/fetchFacilities', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        facilityListTable.value = response.data['result']
        totalFacilityListTable.value = 14
        //totalFacilityListTable.value = response.data['result'].length

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchFacilities()
  })

  const resolveFacilityInventory = (inventory, key) => {
    var value = 0
    inventory.forEach(function(item) {
      value += item[key] ?? 0
    })

    return value.toString().toAccounting()
  }

  String.prototype.toAccounting = function() {
    return parseFloat(this)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  }

  const resolveProgressVariant = progress => {
    if (progress <= 25) return 'error'
    if (progress > 25 && progress <= 50) return 'warning'
    if (progress > 50 && progress <= 75) return 'primary'
    if (progress > 75 && progress <= 100) return 'success'

    return 'secondary'
  }

  return {
    facilityListTable,
    tableColumns,
    searchQuery,
    totalFacilityListTable,
    loading,
    options,
    selectedRows,
    fetchFacilities,
    resolveFacilityInventory,
    resolveProgressVariant,
  }
}
